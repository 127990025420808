<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <validation-provider v-slot="{ errors }" name="Name" rules="required|min:2|max:50">
                    <v-text-field v-model="localItem.name" :error-messages="errors"
                                  :label="$vuetify.lang.t('$vuetify.general.name')"/>
                </validation-provider>
            </v-col>
            <v-col cols="6">
                <v-row justify="end">
                    <v-switch v-model="localItem.enabled"
                              :label="localItem.enabled ? $vuetify.lang.t('$vuetify.general.enabled')
                        : $vuetify.lang.t('$vuetify.general.disabled')"/>
                </v-row>
            </v-col>
            <v-col cols="6">
                <kurcc-image-input :image.sync="localItem.logo"
                                   :name="$vuetify.lang.t('$vuetify.pages.groups.groupPhoto')"/>
            </v-col>
            <v-col cols="6">
                <kurcc-image-preview :src="localItem.logo" alt="group photo"/>
            </v-col>
            <v-col cols="12">
                <validation-provider v-slot="{ errors }" name="Description" rules="required|min:2|max:50">
                    <v-textarea v-model="localItem.description" :error-messages="errors"
                                :label="$vuetify.lang.t('$vuetify.pages.groups.description')"
                                auto-grow clearable no-resize rows="1"/>
                </validation-provider>
            </v-col>
            <v-col cols="12">
                <kurcc-autocomplete
                    :display-function="getStaffFullName"
                    :filter="filterStaffByName"
                    :items="filteredSelectedAdmins"
                    :label="$vuetify.lang.t('$vuetify.pages.groups.admins')"
                    :loading="isLoadingMembers || isLoadingStaff"
                    :selected-items.sync="selectedAdmins"
                    name="Admin"
                    rules=""/>
            </v-col>
            <v-col cols="12">
                <kurcc-autocomplete
                    :display-function="getStaffFullName"
                    :filter="filterStaffByName"
                    :items="filteredSelectedMembers"
                    :label="$vuetify.lang.t('$vuetify.pages.groups.users')"
                    :loading="isLoadingMembers || isLoadingStaff"
                    :selected-items.sync="selectedMembers"
                    name="User"
                    rules=""/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {removeItemFromArray} from '@/modules/app/helpers/utils'
import {filterStaffByName, getStaffFullName} from "@/modules/staff/helpers/kurccAutocompleteHelpers";

export default {
    name: "KurccGroupsDialog",
    props: {
        item: {
            required: true
        }
    },
    components: {
        KurccAutocomplete: () => import('@/modules/app/components/KurccAutocomplete'),
        KurccImageInput: () => import('@/modules/app/components/KurccImageInput'),
        KurccImagePreview: () => import('@/modules/app/components/KurccImagePreview')
    },
    data() {
        return {
            members: [],
            selectedAdmins: [],
            selectedMembers: [],
            isLoadingStaff: false,
            staffLoaded: false,
            isLoadingMembers: false,
            removeItemFromArray,
            filterStaffByName,
            getStaffFullName
        }
    },
    computed: {
        localItem: {
            get() {
                return this.item;
            },
            set(v) {
                this.$emit('input', v)
            }
        },
        filteredSelectedAdmins() {
            return this.$lodash.differenceBy(this.members, this.selectedMembers, (item) => item.id)
        },
        filteredSelectedMembers() {
            return this.$lodash.differenceBy(this.members, this.selectedAdmins, (item) => item.id)
        },
        isDefaultItem() {
            return this.localItem.id === null
        },
    },
    watch: {
        selectedAdmins: function (val) {
            this.localItem.admin_ids = val.map(val => val.id)
        },
        selectedMembers: function (val) {
            this.localItem.member_ids = val.map(val => val.id)
        },
        localItem: {
            handler: 'fetchDataWhenDialogOpens',
            immediate: true
        }
    },
    methods: {
        fetchDataWhenDialogOpens() {
            if (!this.isDefaultItem) this.getAllGroupMembers()

            if (this.isDefaultItem) {
                this.selectedAdmins = []
                this.selectedMembers = []
            }
        },
        getAllStaff() {
            this.isLoadingStaff = true
            this.$store.dispatch('getAllStaff', {withIncludes: false}).then((res) => {
                    this.members = res.items
                }
            ).finally(() => {
                this.isLoadingStaff = false
                this.staffLoaded = true
            })
        },
        getAllGroupMembers() {
            this.isLoadingMembers = true
            this.$store.dispatch('getAllGroupMembers', {
                withIncludes: false,
                group_id: this.localItem.id
            }).then((res) => {
                    this.selectedAdmins = this.$lodash.filter(res.items, staff => staff.isAdminInGroup(this.localItem.id))
                    this.selectedMembers = this.$lodash.difference(res.items, this.selectedAdmins)
                }
            ).finally(() => {
                this.isLoadingMembers = false
            })
        },
    },
    created() {
        this.getAllStaff()
    }
}
</script>
